@tailwind base;
@tailwind components;
@tailwind utilities;
html {
  scroll-behavior: smooth;
}
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-out {
  animation: fadeInOut 1.5s ease-in-out infinite alternate;
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Light gray background for skeleton placeholder */
  border-radius: 8px; /* Rounded corners for smooth effect */
}
@keyframes slideInOutFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  90% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.slide-in-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f56565;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 16px;
  animation: slideInOutFromTop 3s ease-in-out forwards;
  z-index: 1000;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.warning-icon {
  position: relative;
  display: inline-block;
  font-size: 48px; /* Adjust size as needed */
  color: red; /* Color the triangle red */
}

.warning-icon .exclamation {
  color: white; /* Color the exclamation mark white */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the exclamation mark */
  font-size: 0.6em; /* Adjust size if necessary to fit inside the triangle */
}

.slide-in {
  top: 0;
  width: 100%;
  position: fixed;
  color: white;
  justify-content: center;

  text-align: center;
  display: flex;

  animation: slideInFromTop 0.5s ease-out forwards;
  z-index: 10;
}

.picture {
  background-image: url('./Images/240_F_423044511_bCUtUzoO2LUAsOJrZzCtkaX7w3FS7SOZ.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
}
.main {
  background-image: url('./Images/240_F_444918960_3UfJsxKqmwAZzWky7nqCY2EF0GRQQEVt.jpg');
  background-size: cover;
  background-position: center;
}
.nft-font {
  line-height: 1;
  font-family: 'Roboto', sans-serif;
}
/* Header.css */
.glass-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /*background: rgba(255, 255, 255, 0.1); */
  background-color: black;
  /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Glass effect */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Optional border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}
.glass-header2 {
  position: fixed;
  top: 0;
  left: 0;

  /*background: rgba(255, 255, 255, 0.1); */
  background-color: black;
  /* Semi-transparent background */
  backdrop-filter: blur(10px); /* Glass effect */
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Optional border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
}
/*.btn {
  background: rgba(255, 255, 255, 0.2);
}*/
.btn {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.dropdown {
  background-color: rgb(48, 48, 48);
}
.icon {
  transition: transform 0.3s ease;
}

.icon-rotated {
  transform: rotate(90deg); /* Adjust angle as needed */
}

.dropdown-li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.ul {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.walletheader {
  background-color: rgba(255, 255, 255, 0.2);
}
.ful {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.menu {
  right: -100%;

  transition: right 0.3s ease-in-out;
  overflow: hidden;
}

.open {
  right: 0;
}

/*border: 2px solid rgba(255, 255, 255, 0.2);*/
.color-div {
  background: rgba(255, 255, 255, 0.1);
}
.upp:hover {
  background: rgba(255, 255, 255, 0.1);
}
.form {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.Artify-div {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.btn:focus {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.glass-header h1 {
  color: white; /* Text color */
  margin: 0;
}

.single-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes slideInOut {
  0% {
    transform: translateX(50%);
  }
  10% {
    transform: translateX(0);
  }
  90% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.paragraph-container {
  overflow: hidden;
  width: 100%; /* Adjust width to fit your container */
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;
}
.sliding-paragraph-wrapper {
  display: flex;
  width: 100%;
}

.sliding-paragraph {
  display: inline-block;

  white-space: nowrap;
  /* overflow: hidden;*/
  animation: slideInOut 60s linear infinite;
}

/* Style for the scrollbar track */
/* Hide the scrollbar track */
.scroll-container::-webkit-scrollbar {
  width: 12px;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Customize the scrollbar thumb */
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #555; /* Dark ash scrollbar thumb */
  border-radius: 10px;
  transition: opacity 0.3s ease; /* Smooth transition for thumb */
}

/* When scrolling, show the scrollbar thumb */
.scroll-active::-webkit-scrollbar {
  opacity: 1; /* Show scrollbar */
}

/* Fade out when not scrolling */
.scroll-active::-webkit-scrollbar-thumb {
  opacity: 1;
}
.scroll-active {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent; /* Transparent background, dark ash thumb */
  transition: scrollbar-color 0.3s ease;
}

.scroll-container::-webkit-scrollbar-thumb {
  opacity: 0; /* Hide scrollbar thumb by default */
}

/* For Firefox */
.scroll-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Transparent background, dark ash thumb */
  transition: scrollbar-color 0.3s ease;
}

.scroll-active2 {
  scrollbar-color: #555 transparent;
}
.scroll-container2::-webkit-scrollbar {
  width: 12px;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition */
}

/* Customize the scrollbar thumb */
.scroll-container2::-webkit-scrollbar-thumb {
  background-color: #555; /* Dark ash scrollbar thumb */
  border-radius: 10px;
  transition: opacity 0.3s ease; /* Smooth transition for thumb */
}

/* When scrolling, show the scrollbar thumb */
.scroll-active2::-webkit-scrollbar {
  opacity: 1; /* Show scrollbar */
}

/* Fade out when not scrolling */
.scroll-active2::-webkit-scrollbar-thumb {
  opacity: 1;
}
.scroll-active2 {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.1) transparent; /* Transparent background, dark ash thumb */
  transition: scrollbar-color 0.3s ease;
}

.scroll-container2::-webkit-scrollbar-thumb {
  opacity: 0; /* Hide scrollbar thumb by default */
}

/* For Firefox */
.scroll-container2 {
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent; /* Transparent background, dark ash thumb */
  transition: scrollbar-color 0.3s ease;
}

.scroll-active2 {
  scrollbar-color: #555 transparent;
}
.text-area {
  scrollbar-width: none;
}
.chat-message {
  position: relative;
  max-width: 45%;

  padding: 10px;
  border-radius: 10px;

  word-wrap: break-word;
}
/*color: #333;*/
/* Curve effect for the right side 
.chat-message.self::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: rgb(189, 24, 24);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}*/

/* Curve effect for left side messages (if needed) 
.chat-message::after {
  content: '';
  position: absolute;
  bottom: 30px;
  border-top-left-radius: 100px;
  right: 0;
  width: 20px;
  height: 20px;
  background-color: red;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}*/

.message-wrapper.left {
  justify-content: flex-start;
}

.chat-message.left {
  /* Light blue for received messages background-color: #e1f5fe; */
  border-radius: 10px 10px 10px 0px; /* Curve at the top-left */
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  min-width: 60%;

  word-wrap: break-word;
}

/* Right (sent) messages */
.message-wrapper.right {
  justify-content: flex-end;
}

.chat-message.right {
  /* WhatsApp-like green for sent messages */
  border-radius: 10px 10px 0px 10px; /* Curve at the top-right */
  padding: 10px;
  min-width: 60%;

  word-wrap: break-word;
}

/* Optional: Add styles for the text inside the bubbles */
.chat-message {
  font-size: 16px;
}
.message-wrapper {
  display: flex;
  margin: 10px 0; /* Set a fixed height */
  /* Enable vertical scrolling */
}

/* General styling for the image container */
.image-container {
  position: relative;

  /* You can adjust the height based on your needs */
  overflow: hidden;
}

/* Styling for each image */
.image-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease;
  background-size: cover; /* Ensure the whole image is visible */
  background-position: center; /* Center the image in the container */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

/* When the image is active, make it visible */
.image-slide.active {
  opacity: 1;
  z-index: 2;
}
.myDiv {
  transition: transform 0.3s ease; /* Smooth transition */
}

.myDiv:hover {
  transform: translateY(-10px); /* Moves the div 10px up */
}
.cover-photo {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.collection-name {
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.collection-name:hover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.cover-photohover {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
/* App.css */
.App {
  position: relative;
}

.main-content {
  transition: filter 0.3s ease-in-out;
}

/* Apply blur effect */
.blurred {
  filter: blur(5px);
  pointer-events: none; /* Disable clicking when blurred */
}

/* Full-screen loading overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 9999; /* Make sure it's on top of everything */
  pointer-events: none; /* Prevent interaction on the whole page */
}

/* Loading spinner styles */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2); /* Light transparent border */
  border-top: 5px solid white; /* Solid border on top */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.spinner2 {
  width: 25px;
  height: 25px;
  border: 5px solid rgba(255, 255, 255, 0.2); /* Light transparent border */
  border-top: 5px solid white; /* Solid border on top */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
#google_translate_element {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hide spinners in Chrome, Safari, and Edge */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Hide the spinner in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Hide the spinner (increase/decrease button) in Chrome, Safari, and Edge */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner in Firefox */
.no-spinner {
  -moz-appearance: textfield;
}
img {
  pointer-events: none; /* Prevents any mouse interaction */
}

/* Hide Google Translate top bar */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important; /* Fixes any shifting caused by the hidden header */
}

/* Hide the Google Translate iframe container */
.goog-te-gadget-icon {
  display: none !important; /* Hide the icon */
}

.goog-te-gadget-simple {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
